<template>
    <div class="employee-list">
        <multiselect 
            v-model="selected_employee" 
            @input="updateValueAction" 
            id="ajax"
            label="full_name" 
            :custom-label="nameWithId"
            track-by="id" 
            placeholder="Type Name or ID" 
            open-direction="bottom" 
            :options="employees"
            :searchable="true" 
            :loading="isLoading" 
            :internal-search="false" 
            :clear-on-select="false" 
            :show-no-results="false" 
            :hide-selected="false"
            ref="multiselectRef"
            :allow-empty="true"
            @search-change="asyncFind"
            style="font-weight:normal; !important;"
            :multiple="multipleEmployees"
        >
            <template>
                <span slot="noOptions">Type Name or ID</span>
            </template>
        </multiselect>
    </div>
</template>
<script>
import Multiselect from 'vue-multiselect';
export default {
    components: {
        Multiselect
    },
    props: {
        employee_id: {
            type: [Number, String],
            default: null
        },
        reporting_to: {
            type: [Number],
            default: 1
        },
        multipleEmployees: {
            type: [Boolean],
            default: false
        },

    },
    data() {
        return {
            employees: [],
            selected_employee:[],
            selectedCountries: [],
            isLoading: false
        }
    },
    mounted(){
        this.$refs.multiselectRef.$refs.search.setAttribute("autocomplete", "off")
        if (this.employee_id) {
            this.getSelectedEmployee(this.employee_id)
        }
    },
    methods:{
        asyncFind (query) {
            this.employees = [];
            this.isLoading = true;
            if (query) {
                this.getEmployee(query)
            } else {
                this.employees.push(this.selected_employee);
            }
            this.isLoading = false
        },

        updateValueAction(data) {
            if (data) {
                if(this.multipleEmployees)
                {
                    let id=[];
                    data.forEach(employee => {
                        id.push(employee.id);
                    });
                    
                        this.$emit('emp_id', id);
                }
                else
                {
                    this.$emit('emp_id', data.id);
                }
            }
            
        },

        reset(){
            this.selected_employee={id:'', full_name:''};
            this.employees = [];
        },

        getEmployee: function(value) {
            let query = this.getAuthHeaders();
            let data = {
                query: value,
                reporting_to:this.reporting_to
            };
            query['params']= this.deleteEmptyKeys(data)
            this.axios
                .get(this.$api.get_search_employee, query)
                .then(response => {
                    if (response.data.code == 200) {
                        this.employees = response.data.data;
                    } else {
                        this.employees = [];
                    }
                })
                .catch(err => {
                    console.error(err);
                });
        },

        getSelectedEmployee: function(emp_id) {
            let query = this.getAuthHeaders();
            query['params'] = {
                search : emp_id
            }
            this.startProgressBar();
            this.axios
                .get(this.$api.get_employees_list, this.getAuthHeaders())
                .then(response => {
                    if (response.data.code == 200) {
                        let employee = response.data.data[0];
                        this.selected_employee = {
                            id:employee.id, full_name:employee.full_name
                        }
                    }
                    this.updateProgressBar(true);
                })
                .catch(err => {
                    console.error(err);
                    this.updateProgressBar(false);
                });
        },
    }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
    .employee-list .multiselect__option--highlight:after{
        display: none;
    }

    .employee-list .multiselect__option--selected {
        font-weight: normal;
        background-color: #f3f3f3 !important;
    }

    .employee-list .multiselect .multiselect__option--selected.multiselect__option--highlight, .multiselect .multiselect__option--selected.multiselect__option--highlight:after, .tags .multiselect__option--selected.multiselect__option--highlight, .tags .multiselect__option--selected.multiselect__option--highlight:after  {
        color: #fff !important;
        background-color: #007bff !important;
        border-color: #007bff !important;
    }
</style>