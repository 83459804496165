<template>
    <div v-if="!access">
        <NotFound />
    </div>
    <div v-else>
        <div class="pb-2 mb-3 border-bottom">
            <h2>Leave Approvals</h2>
        </div>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <router-link to="/">Dashboard</router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">Leave Approvals</li>
            </ol>
        </nav>
        <b-container fluid>
            <div v-if="uncheck_error">
                <b-alert
                    :show="alertDismissCountDown"
                    dismissible
                    variant="danger"
                    @dismissed="alertDismissCountDown=0"
                    @dismiss-count-down="alertCountDownChanged"
                >{{uncheck_error}}</b-alert>
            </div>
            <div v-if="api_error">
                <b-alert
                    :show="alertDismissCountDown"
                    dismissible
                    variant="danger"
                    @dismissed="alertDismissCountDown=0"
                    @dismiss-count-down="alertCountDownChanged"
                >{{api_error}}</b-alert>
            </div>
            <b-row>
                <b-col lg="3" sm="12" >
                    <div class="form-group row">
                        <label
                            class="col-lg-2 col-md-2 col-sm-12 col-form-label text-nowrap"
                        >Status</label>
                        <div class="col-lg-9 col-md-10 col-sm-12">
                            <b-form-select id="statusinput" v-model="urlBody.status" :options="leave_status_options"></b-form-select>
                        </div>
                    </div>
                </b-col>
                <b-col  v-if="hasPermission('CAN_APPROVE_ALL_LEAVES')">
                    <b-form-group
                        label="Leave Approvals for"
                        label-cols-sm="6"
                        label-align-sm="right"
                        label-size="sm"
                        class="mb-0"
                        label-for="leaveapprovalfor"
                        style="font-size:1rem !important;"
                    >
                        <b-input-group >
                        <b-form-select id="leaveapprovalfor" v-model="urlBody.leave_approvals_of" :options="leave_approval_options"></b-form-select>
                        </b-input-group>
                    </b-form-group>
                </b-col>
                <b-col lg="1">
                        <button
                            type="button"
                            class="btn btn-primary text-right "
                            v-on:click="onSelection()"
                        >Filter</button>
                </b-col>
                <b-col :lg="(hasPermission('CAN_APPROVE_ALL_LEAVES')? 0 : 4)">
                </b-col>
                <b-col lg="4" class="text-right">
                    <router-link
                        v-if="hasPermission('CAN_APPLY_TEAM_LEAVES')"
                        :to="{name: 'apply-employee-leaves'}"
                        class="btn btn-light text-right "
                        tag="button"
                    >
                         Apply Team Leave
                    </router-link>
                     <router-link
                        v-if="hasAnyPermission(['CAN_VIEW_ALL_LEAVE_BALANCE','CAN_VIEW_TEAM_LEAVE_BALANCE'])"
                        :to="{name: 'team-leave-balance'}"
                        class="btn btn-light text-nowrap ml-2"
                        tag="button"                        
                    >
                        Team Leave Balance
                    </router-link>
                </b-col>
            </b-row>
            <b-row class="mb-1rem">
                <b-col lg="5">
                    <b-form-group
                        label="Search"
                        label-cols-sm="1"
                        label-align-sm="left"
                        label-size="sm"
                        label-for="filterInput"
                        class="mb-0"
                        style="font-size:1rem !important;"
                    >
                        <b-input-group size="md" >
                            <div class="col-md-9 ">
                                <EmployeeList :reporting_to="employeeReportingto" @emp_id="getEmpId" ref="employeeId"></EmployeeList>
                            </div>
                            <b-button
                                :disabled="!filter"
                                @click="onFiltered"
                                class="btn btn-primary col-md-2"
                                variant="primary"
                            >Search</b-button>
                        </b-input-group>
                    </b-form-group>
                </b-col>
                <b-col lg="7" class="text-right" >
                    <button
                        :disabled="!selected.length"
                        type="button"
                        class="btn btn-primary text-right btn-approve"
                        @click="approveAllInfo()"
                    >Approve
                    </button>
                </b-col>
            </b-row>
            <b-table
                show-empty
                striped
                hover
                :items="items"
                :fields="computedFields"
                no-local-sorting
                @sort-changed="customSorting"
                responsive
                @row-clicked="rowCilck"
                style="cursor:pointer"
            >
                <template #head(selected)="">
                    <div class="form-checkbox" >
                        <input  type="checkbox" class="checkboxsize" v-model="selectAll" @click="select">
                            <i class="form-icon"  ></i>
                    </div>
                </template>
                <template v-slot:cell(applied_by)="row">
                    {{row.item.employee.full_name}} [IES-{{row.item.employee.id}}]
                </template>    
                <template v-slot:cell(reporting_to)="row">
                    {{row.item.employee.reporting_to.full_name}} [IES-{{row.item.employee.reporting_to.id}}]
                </template>  
                <template v-slot:cell(selected)="row" >
                    <b-form-group>
                        <input type="checkbox" class="checkboxsize" :value="row.item.id" v-model="selected"  v-if="row.item.status =='APPLIED'" @change="getSelected"/>
                    </b-form-group>
                </template>
                <template v-slot:cell(status)="row">
                       <b-badge :variant="setBadgeColor(row.item.status)" > {{ row.item.status}} </b-badge>
                </template>
                <template v-slot:cell(created_at)="row">
                    {{ convertToDate(row.item.created_at) }}
                </template>
                <template v-slot:cell(reason)="row">
                    <p class="text-truncate" style="width:70px" v-b-tooltip.hover :title="row.item.reason">
                        {{row.item.reason}}
                    </p>
                </template>
            </b-table>
            <b-row align-h="between" v-if="totalRows">
                <b-col lg="4" class="p-3">
                    <div class="row input-group">
                        <div class="input-group-append my-2">Showing</div>
                        <div class="mx-1em">
                            <select
                                class="form-control"
                                v-model="urlBody.per_page"
                                @change="changePageCount"
                            >
                                <option
                                    v-for="option in pageOptions"
                                    :key="option.index"
                                >{{option}}</option>
                            </select>
                        </div>
                        <div class="input-group-append my-2" v-if="totalRows>urlBody.per_page">of {{totalRows}}</div>
                    </div>
                </b-col>
                <b-col lg="4" class="p-3">
                    <nav aria-label="Page navigation" class="justify-content-center">
                        <ul class="pagination float-right">
                            <li class="page-item">
                                <span
                                    class="page-link"
                                    v-on:click="pagination(urlBody.page - 1)"
                                    v-if="urlBody.page > 1"
                                    style="cursor:pointer"
                                >
                                    <font-awesome-icon class="mr-2" icon="angle-double-left"></font-awesome-icon>Previous
                                </span>
                                <span
                                    class="page-link text-muted"
                                    style="cursor:not-allowed !important;"
                                    v-else
                                >
                                    <font-awesome-icon class="mr-2" icon="angle-double-left"></font-awesome-icon>Previous
                                </span>
                            </li>
                            <li class="page-item">
                                <span
                                    class="page-link text-muted"
                                    style="cursor:not-allowed !important;"
                                    v-if="urlBody.page == lastPage"
                                >
                                    Next
                                    <font-awesome-icon class="mr-2" icon="angle-double-right"></font-awesome-icon>
                                </span>
                                <span
                                    class="page-link"
                                    v-else
                                    v-on:click="pagination(urlBody.page +1)"
                                    style="cursor:pointer"
                                >
                                    Next
                                    <font-awesome-icon class="mr-2" icon="angle-double-right"></font-awesome-icon>
                                </span>
                            </li>
                        </ul>
                    </nav>
                </b-col>
            </b-row>
        </b-container>
        <!-- Approve All modal -->

        <b-modal
            :id="approveAllModal.id"
            :title="approveAllModal.title"
            centered
            size="md"
            @hide="resetApproveAllInfoModal"
        >
            <p v-html="approveAllModal.message" class="float-left"></p>
            <br> <br>
            
            <div slot="modal-footer" class="w-100">
                <b-button class="mt-3 float-right" @click="approveAllCancel()">No</b-button>
                <b-button
                    class="mt-3 float-right btn"
                    variant="danger"
                    v-on:click="approveAllLeaves()"
                    style="margin-right:10px;"
                >Yes</b-button>
            </div>
        </b-modal>
        <!-- Info modal -->
        <!-- <b-modal
            :id="infoModal.id"
            :title="infoModal.title"
            centered
            size="md"
            @hide="resetInfoModal"
        >
            <p v-html="message" class="float-left"></p>
            <br> <br>
            <label class="float-left" v-if="infoModal.value === 'reject'">
                Comment
                <span class="text-danger" style="font-weight:bold;">*</span>
            </label>
            <b-form-textarea
                v-if="infoModal.value === 'reject'"
                placeholder="Reject Comment..."
                v-model="comment"
                rows="5"
            ></b-form-textarea>
            <div slot="modal-footer" class="w-100">
                <b-button class="mt-3 float-right" @click="cancel()">No</b-button>
                <b-button
                    v-if="infoModal.value === 'reject'"
                    class="mt-3 float-right btn btn-primary"
                    variant="danger"
                    v-on:click="leaveApproval(infoModal.item_id,infoModal.status)"
                    style="margin-right:10px;"
                    :disabled="!comment"
                >Yes</b-button>
                <b-button
                    v-else
                    class="mt-3 float-right btn"
                    variant="danger"
                    v-on:click="leaveApproval(infoModal.item_id,infoModal.status)"
                    style="margin-right:10px;"
                >Yes</b-button>
            </div>
        </b-modal> -->
    </div>
</template>

<script>
import NotFound from "../../errors/NotFound";
import default_error from "../../../assets/js/global";
import EmployeeList from "@/components/EmployeeList.vue";
export default {
    components: {
        NotFound,
        EmployeeList
    },
    data() {
        return {
            urlBody: {
                sort_by: "",
                order: "",
                page: 1,
                per_page: 10,
                status: "APPLIED",
                leave_approvals_of:"my_team",
            },
            api_error: "",
            uncheck_error:"",
            employeeReportingto:1,
            sortBy: "id",
            sortDesc: false,
            filter: null,
            selectAll: false,
            selected: [],
            get_status_applied:0,
            fields: [
                { key: "selected"},
                { key: "applied_by", label:"Applied By"},
                { key: "reporting_to"},
                { key: "from_date", label: "From Date", sortable: true,
                    formatter: value => {
                        return this.dateformat(value);
                    }
                },
                { key: "to_date", label: "To Date", sortable: true,
                    formatter: value => {
                        return this.dateformat(value);
                    }
                },
                { key: "days", label:"Days"},
                { key: "created_at" ,label: "Applied Date",sortable: true},
                { label: "Leave Type", key: "type" },
                { key: "reason" },
                { key: "status"}
            ],
            leave_status_options: [
                { value: 'APPLIED', text: 'APPLIED' },
                { value: 'APPROVED', text: 'APPROVED' },
                { value: 'REJECTED', text: 'REJECTED' },
            ],
            leave_approval_options: [
                { value: 'my_team', text: 'My Team' },
                { value: 'all_leaves', text: 'All' },
            ],
            badge_colors:[
                {
                    status: "APPLIED",
                    color:'info'
                },
                {
                    status: "APPROVED",
                    color:'success'
                },
                {
                    status: "REJECTED",
                    color:'danger'
                }
            ],
            items: null,
            totalRows: 1,
            lastPage: 1,
            pageOptions: [10, 25, 50, 100],
            access: true,
            alertDismissCountDown: 0,
            alertDismissSecs: 10,
            infoModal: {
                id: "modal-sm",
                title: "",
                item_id: "",
                status:"",
                value:""
            },
            approveAllModal: {
                id: "modal-sm",
                title: "",
                message:"",
            },
            comment:"",
            message:"",
        };
    },
   computed: {
        computedFields: function() {
            let fields = this.fields;
            if (!this.get_status_applied) {
                fields= fields.filter((item) => item.key !== 'selected');
            }
            return fields
        }
    },
    mounted() {
        this.access = this.hasAnyPermission(['CAN_APPROVE_LEAVES', 'CAN_APPROVE_ALL_LEAVES']);
        this.onPageLoad();
        this.employeeReportingto = (this.hasAnyPermission(['CAN_APPROVE_ALL_LEAVES'])) ? 0 : 1;
    },
    methods: {
        alertCountDownChanged(alertDismissCountDown) {
            this.alertDismissCountDown = alertDismissCountDown;
        },

        showAlert() {
            this.alertDismissCountDown = this.alertDismissSecs;
        },

        getSelected(){
            if (this.selected.length == this.get_status_applied){
                this.selectAll = true;
            } else {
                this.selectAll =false;
            }
        },
        
        getEmpId(id) {
            this.filter = id;
        },
        
        approveAllLeaves: function() {
            this.startProgressBar();  
            const data = {ids: this.selected};
            this.axios.post(this.$api.multi_leaves_approve, data, this.getAuthHeaders()).then(() => {
                this.$root.$emit("bv::hide::modal", this.approveAllModal.id);
                this.selectAll = false;
                this.updateProgressBar(true);
                this.getLeaves(this.getDefaultFilters());
            })
            .catch(err => {
                if (!err.response) {
                    this.api_error = default_error.server_error;
                } else if (
                    err.response &&
                    err.response.data &&
                    err.response.data.message
                ) {
                    this.api_error = err.response.data.message;
                } else {
                    this.api_error = default_error.server_error;
                    console.error(err.response.data);
                }
                this.updateProgressBar(false);
            });
        },

        select() {
            this.selected = [];
			if (!this.selectAll) {
				for (let i in this.items) {
                   if(this.items[i].status =='APPLIED'){
                       this.selected.push(this.items[i].id);
                   }
				}
			}
		},

        approveAllInfo() {
            if (!this.selected.length){
                this.uncheck_error = "Please select one or more leaves";
                this.alertDismissCountDown =10;
            } else {
                this.approveAllModal.title = `Approve All Leaves Confirmation`;
                this.approveAllModal.message = 'Are you sure you want to approve Selected Leaves?';
                this.$root.$emit("bv::show::modal", this.approveAllModal.id);
            }
        },

        clearSearch (event) {
            if (event.target.value === ""){
                if(this.urlBody.search != ''){
                    this.urlBody.search = '';
                    var newQuery = this.deleteEmptyKeys(this.urlBody);
                    this.$router
                        .push({
                            path: this.$route.params[0],
                            query: newQuery
                        })
                    this.getLeaves(newQuery);
                }
            }
        },
        
        onSelection() {
            this.selected = [];
            this.selectAll =false;
            var query =this.deleteEmptyKeys(this.urlBody);
            this.getLeaves(query);
            this.$refs.employeeId.reset();
        },

        onFiltered() {
            var query = {search :this.filter};
            this.getLeaves(query);
        },
        
        resetInfoModal() {
            this.infoModal.title = "";
            this.infoModal.item_id = null;
            this.message = "";
            this.cancel();
        },

        resetApproveAllInfoModal() {
            this.approveAllModal.title = "";
            this.approveAllCancel();
        },

        approveAllCancel(){
            this.$root.$emit("bv::hide::modal", this.approveAllModal.id);
        },

        cancel(){
            this.$root.$emit("bv::hide::modal", this.infoModal.id);
        },
        
        convertToDate(D){
            var date = new Date(D);
            return ('0' + date.getDate()).slice(-2) + '-' + ('0' + (date.getMonth()+1)).slice(-2) + '-'+ date.getFullYear();
        },

        getDefaultFilters() {
            let api_params = {
                page: this.urlBody.page,
                per_page: this.urlBody.per_page,
                sort_by: this.urlBody.sort_by,
                order: this.urlBody.order,
                status: this.urlBody.status,
                leave_approvals_of: this.urlBody.leave_approvals_of,
            };
            return api_params;
        },
        
        getLeaves: function(params) {
            this.startProgressBar();
            var query = this.getAuthHeaders();
            query["params"] = this.deleteEmptyKeys(params);
            this.axios.get(this.$api.leaves_approval, query).then(response => {
                const result = response.data.data;
                this.items = result.data;
                this.get_status_applied=0;
                for (let i in this.items) {
                   if(this.items[i].status =='APPLIED'){
                       this.get_status_applied = this.get_status_applied +1;
                   }
				}
                this.urlBody.page = result.current_page;
                this.lastPage = result.last_page;
                this.urlBody.per_page = result.per_page;
                this.totalRows = result.total;
                if(params.search) {
                    this.$refs.employeeId.getSelectedEmployee(params.search);
                }
                this.api_error = "";
                this.updateProgressBar(true);
                this.$router.push({
                    path: this.$route.params[0],
                    query: this.deleteEmptyKeys(params)
                }).catch(()=>{})
            }).catch(err => {
                if (!err.response) {
                    this.api_error = default_error.server_error;
                } else if (
                    err.response &&
                    err.response.data &&
                    err.response.data.message
                ) {
                    this.api_error = err.response.data.message;
                } else {
                    this.api_error = default_error.server_error;
                    console.error(err.response.data);
                }
                this.items = [];
                this.updateProgressBar(false);
                this.showAlert();
            });
        },

        customSorting: function(ctx) {
            if (ctx.sortBy == "" || ctx.sortBy == null) {
                return;
            }
            let urlBody = this.deleteEmptyKeys(this.$route.query);
            urlBody.order = ctx.sortDesc ? "asc" : "desc";
            urlBody.sort_by = ctx.sortBy;
            urlBody.page = 1;
            this.api_error = "";
            this.getLeaves(urlBody);
        },

        pagination: function(pageNumber) {
            var newQuery = this.deleteEmptyKeys(this.$route.query);
            newQuery.page = pageNumber;
            this.api_error = "";
            this.getLeaves(newQuery);
        },

        changePageCount: function() {
            let newQuery = this.deleteEmptyKeys(this.$route.query);
            newQuery.per_page = this.urlBody.per_page;
            this.api_error = "";
            this.getLeaves(newQuery);
        },

        setBadgeColor(value) {
            let data = this.badge_colors.find(status => status.status == value);
            return data.color;
        },

        rowCilck(item) {
            this.$router.push({name: 'leave-approval-details', params:{id: item.id}});
        },

        dateformat (date) {
            var dateFormat = new Date(date);
            var dd = String(dateFormat.getDate()).padStart(2, '0');
            var mm = String(dateFormat.getMonth() + 1).padStart(2, '0');
            var yyyy = dateFormat.getFullYear();
            return dd + '-' + mm + '-' + yyyy;
        },

        setDefaultFilters() {
            let api_params = this.deleteEmptyKeys(this.$route.query);
            this.urlBody.status = api_params.status
                ? api_params.status
                : this.urlBody.status;
            this.urlBody.sort_by = api_params.sort_by
                ? api_params.sort_by
                : this.urlBody.sort_by;
            this.urlBody.order = api_params.order
                ? api_params.order
                : this.urlBody.order;
            this.urlBody.leave_approvals_of = api_params.leave_approvals_of
                ? api_params.leave_approvals_of
                : this.urlBody.leave_approvals_of;
            this.filter = api_params.search
                ? api_params.search
                : this.filter;
            this.urlBody.page = api_params.page
                ? api_params.page
                : this.urlBody.page;
            this.urlBody.per_page = api_params.per_page
                ? api_params.per_page
                : this.urlBody.per_page;
        },
        
        onPageLoad() {
            const api_params =
                Object.keys(this.$route.query).length > 0
                    ? this.$route.query
                    : this.getDefaultFilters();
            this.setDefaultFilters();
            this.getLeaves(api_params);
        },

    }
};
</script>
<style lang="scss">
@import "../../../assets/css/custom.scss";
.btn-outline-light-black {
    border: 1px solid black;
}
.checkboxsize   {
    height: 15px;
    width: 15px;
}
.btn-approve:disabled {
    color: #ffffff !important;
    background-color:#007BFF !important;
    border-color: #007BFF !important;
    cursor: not-allowed;
}
</style>